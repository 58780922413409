import emailIcon from "@assets/icon-email.svg";
import facebookIcon from "@assets/icon-facebook.svg";
import instagramIcon from "@assets/icon-instagram.svg";
import linkedinIcon from "@assets/icon-linkedin.svg";
import telegramIcon from "@assets/icon-telegram.svg";
import twitterIcon from "@assets/icon-twitter.svg";
import whatsappIcon from "@assets/icon-whatsapp.svg";
import giftIcon from "@assets/icons/icon-gift.svg";
import hamburgerForDesktopIcon from "@assets/icons/icon-hamburger-desktop.svg";
import hamburgerForMobileIcon from "@assets/icons/icon-hamburger-mobile.svg";
import profileIcon from "@assets/icons/icon-profile.svg";
import searchIcon from "@assets/icons/icon-search.svg";

import { IconName } from "./types";

export const getIconSrc = (icon: IconName) => {
  switch (icon) {
    case IconName.facebook:
      return facebookIcon;

    case IconName.telegram:
      return telegramIcon;

    case IconName.email:
      return emailIcon;

    case IconName.linkedin:
      return linkedinIcon;

    case IconName.twitter:
      return twitterIcon;

    case IconName.whatsapp:
      return whatsappIcon;

    case IconName.instagram:
      return instagramIcon;

    case IconName.profile:
      return profileIcon;

    case IconName.search:
      return searchIcon;

    case IconName.hamburgerForDesktop:
      return hamburgerForDesktopIcon;

    case IconName.hamburgerForMobile:
      return hamburgerForMobileIcon;

    case IconName.gift:
      return giftIcon;
  }
};
