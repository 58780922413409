import { ReactElement } from "react";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import Header from "@components/Header/Header";
import { getStatusCodeErrorMessage, GoogleAdsSlotFactory } from "@util/helpers";

type CustomErrorProps = {
  statusCode?: number;
};

export default function CustomError({
  statusCode = 404,
}: CustomErrorProps): ReactElement {
  const errorMessage = getStatusCodeErrorMessage(statusCode);

  return (
    <>
      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1("homepage"),
          adsClassName:
            "min-h-[90px] flex flex-col justify-center items-center",
        }}
      />

      <Header />

      <div className="container px-3 py-3">
        <p className="mb-0 mt-3">{errorMessage}</p>
      </div>
    </>
  );
}
