/**
 * Company: SPHMedia
 * Description: ESG Section Layout
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import esgLogo from "@assets/verticals/esg-logo.png";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import {
  useBreadCrumbState,
  useCategoriesFromPath,
} from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import Overview from "@pages/Section/components/Overview/Overview";
import type { SectionDefaultProps } from "@pages/Section/types";
import { sectionNavigationItems } from "@util/constant";
import { GoogleAdsSlotFactory } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";
import cx from "classnames";

export default function ESGSectionLayout({
  context: {
    data: { title, overview },
  },
}: SectionDefaultProps): ReactElement {
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({ targeting: { key: "page", value: "listing" } });
  const { parentCategory, childCategory } = useCategoriesFromPath();

  const mostPopular = useMostReadArticleList();
  const breakingNews = useTrimmedBreakingNews();
  const adBlockDetected = useDetectAdBlock();

  const parentCategoryData = sectionNavigationItems?.[parentCategory];
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description="THE BUSINESS TIMES ESG - Find ESG News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="article"
        slug={RouteFactory.esg}
      />

      <GAData
        chapter1={childCategory?.replaceAll("-", " ") || ""}
        level2={parentCategory?.replaceAll("-", " ") || ""}
        title={title}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(parentCategory),
          adsClassName:
            "min-h-[90px] flex flex-col justify-center items-center",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(parentCategory)}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header
        displayBreadcrumb={
          parentCategory &&
          sectionNavigationItems?.[parentCategory] &&
          isShowBreadCrumb
            ? true
            : false
        }
        parentCategory={parentCategory}
        childCategory={childCategory}
        categoryDataMapping={sectionNavigationItems}
      />

      {parentCategory && sectionNavigationItems?.[parentCategory] ? (
        <div className="w-full flex-none">
          <Container rootClassName="w-full">
            <h1
              className={cx(
                "pb-3 pt-6 font-playfair text-8xl font-semibold uppercase text-gray-850"
              )}
            >
              {parentCategoryData.label}
            </h1>

            <div ref={breadcrumbsRef}>
              <Breadcrumb
                className="container m-auto"
                parentCategory={parentCategory}
                childCategory={childCategory}
                categoryDataMapping={sectionNavigationItems}
              />
            </div>
          </Container>
        </div>
      ) : null}

      <Container rootClassName="pt-6">
        <Row>
          <Column rootClassName="w-full">
            <NewsletterSignUpPanel
              rootClassName="mb-6 lg:py-0"
              containerClassName="lg:pl-0"
              firstColumnClassName="lg:!w-5/12"
              secondColumnClassName="mb-4 lg:!w-6/12"
              descriptionComponent={
                <div
                  className={cx(
                    "mb-4 flex items-center justify-center",
                    "lg:mb-0 lg:justify-start"
                  )}
                >
                  <img
                    className="aspect-square w-[48px] lg:w-[90px]"
                    src={esgLogo}
                    alt="ESG"
                    width={90}
                    height={90}
                  />
                  <h4
                    className={cx(
                      "ml-4 font-public-sans text-base font-semibold lg:text-4xl"
                    )}
                  >
                    Get weekly{" "}
                    <span className="text-verticals-esg">{"ESG "}</span>
                    <br className="md:hidden lg:inline xl:hidden" />
                    updates in your inbox
                  </h4>
                </div>
              }
              newsletter={BT_NEWSLETTERS.filter(
                (newsletter) => newsletter.type === NEWSLETTER_TYPE.ESG
              )}
            />
          </Column>

          <Column rootClassName="w-full lg:w-8/12">
            <Overview data={overview} />
          </Column>

          <Column rootClassName="w-full lg:w-4/12">
            <div className="mb-5 lg:h-[800px]">
              <Advertisement
                rootClassName="lg:sticky lg:top-[120px]"
                adUnitProps={{
                  type: AdvertisementTypeEnum.IMU2,
                  slot: GoogleAdsSlotFactory.imu2(parentCategory),
                }}
              />
            </div>

            <BreakingNews
              data={breakingNews}
              rootClassName="max-w-[300px] mx-auto mb-6"
            />

            <MostPopular
              data={mostPopular}
              rootClassName="max-w-[300px] mx-auto"
            />
          </Column>
        </Row>
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(parentCategory),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </div>
  );
}
