import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { LifestyleLuxeContext } from "@app/types/Page";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import LuxeCard from "@components/ArticleCard/LuxeCard";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { GoogleAdsSlotFactory } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import LifestyleLuxeArticle from "./LifestyleLuxeArticles";

type LifestyleLuxeContentProps = Pick<LifestyleLuxeContext, "data">;

export default function LifestyleLuxeContent({
  data: { title, articles },
}: LifestyleLuxeContentProps): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  const adSlot = RouteFactory.lifestyleLuxe.replace("/", "");
  const lifestyleUniqueName = RouteFactory.lifestyle.replace("/", "");
  const luxeUniqueName = RouteFactory.lifestyleLuxe.replace("/", "");
  const firstArticle = articles?.[0];
  const isSubscriber = OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description="THE BUSINESS TIMES BT Luxe - Find BT Luxe News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="article"
        slug={RouteFactory.lifestyleLuxe}
      />

      <GAData
        level2={RouteFactory.lifestyle.replace("/", "")}
        chapter1={"bt luxe"}
        title={title}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(adSlot),
          adsClassName:
            "min-h-[90px] flex flex-col justify-center items-center",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(adSlot)}
        {...{
          isPrestitialEnabled,
          topOverlayImpressions,
          topOverlayValidity,
        }}
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={lifestyleUniqueName}
        childCategory={luxeUniqueName}
        categoryDataMapping={sectionNavigationItems}
      />

      <Container>
        <h1 className="pb-3 pt-6 font-playfair text-6xl font-semibold uppercase text-gray-850 md:text-8xl">
          Lifestyle
        </h1>

        <div ref={breadcrumbsRef}>
          <Breadcrumb
            className="container m-auto"
            parentCategory={lifestyleUniqueName}
            childCategory={luxeUniqueName}
            categoryDataMapping={sectionNavigationItems}
          />
        </div>
      </Container>

      <LuxeCard
        id={firstArticle.id}
        title={
          firstArticle.displaySetting?.displayHeadline || firstArticle.title
        }
        media={firstArticle.media}
        kicker={firstArticle.kicker?.fields?.[0]?.value}
        section={firstArticle.sections?.[0]}
        paywall={
          isSubscriber ? firstArticle.paywall?.contentAccess === "1" : false
        }
        blurb={firstArticle.blurb}
        rootClassName="mb-16"
        defaultImage={{
          directoryName: firstArticle.sections?.[0].uniqueName || "",
        }}
        slug={firstArticle.urlPath}
      />

      <Container rootClassName="pb-8">
        <LifestyleLuxeArticle
          articles={articles.slice(1)}
          articleIds={articles.map(({ id }) => id)}
        />
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(adSlot),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </div>
  );
}
