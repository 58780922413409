/**
 * Company: SPHMedia
 * Description: Default Section Layout
 */

import { ReactElement, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getActiveSectionPath } from "@app/components/Breadcrumb/helpers";
import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { TrimmedBreakingNewsArticle } from "@caas/types";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import KeywordFilter from "@components/KeywordFilter";
import { KeywordType } from "@components/KeywordFilter/types";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import Overview from "@pages/Section/components/Overview/Overview";
import PrPageIntroduction from "@pages/Section/components/PrPageIntroduction";
import {
  getFormattedTextForAds,
  getTermPath,
  isEntityTagType,
} from "@pages/Section/helpers";
import { sectionRequests } from "@pages/Section/Section.server";
import type { SectionDefaultProps } from "@pages/Section/types";
import { sectionNavigationItems } from "@util/constant";
import { cn, GoogleAdsSlotFactory } from "@util/helpers";
import cx from "classnames";

export default function DefaultLayout({
  context: {
    data: { entity, title, overview },
  },
}: SectionDefaultProps): ReactElement {
  const { pathname } = useLocation();
  const mostPopular = useMostReadArticleList();

  const termName = entity.name;
  const termPath = getTermPath(entity) || pathname.replace("/", "");
  const slot = isEntityTagType(entity) ? "keywords" : termPath;
  const parentCategoryPath = termPath.split("/")?.[0];
  const childCategoryPath = termPath.split("/")?.[1];
  const activeSectionPath = getActiveSectionPath(pathname, termPath);

  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: [
        { key: "page", value: "listing" },
        ...(isEntityTagType(entity)
          ? [{ key: "bttags", value: getFormattedTextForAds(termName) }]
          : []),
      ],
    });

  const [breakingNews, setBreakingNews] = useState<
    TrimmedBreakingNewsArticle[]
  >([]);

  useEffect(() => {
    const callFetch = async () => {
      const trimmedBreakingNews =
        await sectionRequests.fetchTrimmedBreakingNews();
      setBreakingNews(trimmedBreakingNews);
    };

    callFetch();
  }, []);
  const parentCategoryData = sectionNavigationItems?.[parentCategoryPath];
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${termName} - Find ${termName} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="article"
        slug={`/${termPath}`}
      />

      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(slot),
          adsClassName:
            "min-h-[90px] flex flex-col justify-center items-center",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(slot)}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header
        displayBreadcrumb={!termPath.includes("keywords") && isShowBreadCrumb}
        parentCategory={parentCategoryPath}
        childCategory={childCategoryPath}
        categoryDataMapping={sectionNavigationItems}
      />

      {termPath &&
      sectionNavigationItems[termPath] &&
      !termPath.includes("keywords") ? (
        <div className="flex-none">
          <Container>
            <h1
              className={cx(
                "pb-3 pt-6 font-playfair text-6xl font-semibold uppercase text-gray-850 md:text-8xl",
                {
                  "border-b border-gray-850":
                    !parentCategoryData?.children?.length,
                }
              )}
            >
              {parentCategoryData.label}
            </h1>

            <div ref={breadcrumbsRef}>
              <Breadcrumb
                className="m-auto"
                parentCategory={parentCategoryPath}
                childCategory={childCategoryPath}
                categoryDataMapping={sectionNavigationItems}
                extraBreadcrumbItems={
                  <div
                    className={cn(
                      "relative box-border flex shrink-0 items-center border-r border-gray-175 pr-3 font-poppins text-sm tracking-tightest text-gray-850 hover:opacity-80",
                      {
                        "font-bold":
                          activeSectionPath === parentCategoryData?.link,
                      }
                    )}
                  >
                    <Link
                      to={`${parentCategoryData?.link}?ref=listing-menubar`}
                      reloadDocument
                    >
                      Top stories
                    </Link>
                  </div>
                }
              />
            </div>
          </Container>
        </div>
      ) : null}

      <Container>
        <>
          {isEntityTagType(entity) ? (
            <KeywordFilter
              followtextButton
              tag={entity}
              rootClassName="mb-7 py-3 pt-6 inline-block w-full border-b border-gray-850 "
              tagClassName="border-none !font-playfair !text-6xl md:!text-8xl !p-0 text-gray-850 !uppercase !text-left !text-normal"
              toolTipClassName={
                "!right-0 top-full min-[425px]:!left-auto min-[425px]:top-1/2 min-[425px]:flex min-[425px]:-translate-y-1/2 min-[425px]:-translate-x-0 min-[425px]:items-center xl:left-auto xl:top-full xl:block xl:-translate-x-0 xl:translate-y-0 top-full min-[425px]:top-full min-[425px]:!block left-auto w-[200px] xs:w-[250px] xl:translate-x-0"
              }
              toolTipArrowClassName={
                "xl:ml-0 xl:mr-10 md:mr-10 border-b-[8px] border-l-[6px] border-r-[6px] border-x-transparent border-b-black mr-3 min-[425px]:border-r-transparent min-[425px]:!border-b-black min-[425px]:border-t-transparent relative left-[90%] md:left-[80%] mr-0 border-t-transparent ml-0"
              }
              disableTooltip
              keywordType={KeywordType.listing}
            />
          ) : null}
        </>
      </Container>
      <Container rootClassName="pt-6">
        <>{pathname === RouteFactory.pr ? <PrPageIntroduction /> : null}</>

        <Row>
          <Column rootClassName="w-full lg:w-8/12 mb-6">
            <Overview
              data={overview}
              testId={"section-listing-component"}
              slot={slot}
            />
          </Column>

          <Column rootClassName="w-full lg:w-4/12 mb-6">
            <div className="mb-6 lg:h-[800px]">
              <Advertisement
                rootClassName="lg:sticky lg:top-[60px]"
                adUnitProps={{
                  type: AdvertisementTypeEnum.IMU2,
                  slot: GoogleAdsSlotFactory.imu2(slot),
                }}
              />
            </div>

            <BreakingNews
              data={breakingNews}
              rootClassName="max-w-[300px] mx-auto mb-6"
            />

            <MostPopular
              data={mostPopular}
              rootClassName="max-w-[300px] mx-auto"
            />
          </Column>
        </Row>
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(slot),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </div>
  );
}
