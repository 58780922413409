/**
 * Company: SPHMedia
 * Description: International Global Latest Layout
 */

import { ReactElement, useState } from "react";
import { RouteFactory } from "@app/routePaths";
import type { ArticleDataObject } from "@app/types/Cue";
import aseanLogo from "@assets/verticals/asean-logo-black.svg";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import Container from "@components/Container/Container";
import FooterVerticalBanner from "@components/Footer/FooterComponents/FooterVerticalBanner";
import Header from "@components/Header/Header";
import LoadMore from "@components/LoadMore/LoadMore";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import VerticalBlockTitle from "@components/VerticalBlockTitle/VerticalBlockTitle";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { ASEAN_DESCRIPTION } from "@pages/Section/layouts/Verticals/utils/constant";
import { fetchMoreStoriesData } from "@pages/Section/Section.server";
import type { SectionDefaultProps } from "@pages/Section/types";
import { getArticleBlurb } from "@util/helpers";
import { compact } from "lodash-es";

import AseanBanner from "./components/AseanBanner";
import AseanNewsletterDescription from "./components/AseanNewsletterDescription";

const variant: CardVariations = {
  image: {
    position: "right",
    width: "w-4/10 md:w-2/10 lg:w-3/20",
    extraClass: "order-3",
  },
  content: {
    width: "w-6/10 md:w-8/10 lg:17/20",
    extraClass: "pr-4",
    blurb: {
      extraClass: "!font-poppins text-gray-850",
    },
  },
  kicker: {
    color: "text-gray-515",
    extraClass: "font-poppins text-4xs",
  },
  title: {
    color: "!text-gray-850",
    size: "text-xs",
    extraClass:
      "font-poppins font-semibold !leading-normal hover:text-verticals-asean-hover",
  },
};

export default function AseanBusinessVerticalLatest({
  context: {
    data: { title, overview },
  },
  parentCategory,
  childCategory,
}: SectionDefaultProps): ReactElement {
  useGlobalAdSetting({ targeting: { key: "page", value: "listing" } });
  const [latestStories, setLatestStories] =
    useState<ArticleDataObject[]>(overview);
  const [hasMoreStories, setHasMoreStories] = useState(true);
  const uniqueCategoryQuery = compact([
    parentCategory !== "keywords" && parentCategory,
    childCategory,
  ]).join("_");

  const handleLoadMore = async () => {
    const moreStories = await fetchMoreStoriesData(
      uniqueCategoryQuery,
      parentCategory || "",
      latestStories?.[latestStories.length - 1]
    );
    if (moreStories.length < 10) setHasMoreStories(false);
    setLatestStories([...latestStories, ...moreStories]);
  };

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description="Read more at The Business Times."
        ogType="article"
        slug={`${RouteFactory.aseanBusiness}/latest`}
      />

      <Header />

      <Container>
        <AseanBanner />
      </Container>

      {/* Top Stories */}
      <div className="container px-[var(--gutter-x-sm)] py-[var(--gutter-y)]">
        <VerticalBlockTitle
          id="asean-business-vertical-latest"
          rootClassName="mb-4"
          text="Latest"
        />
        <ul
          data-testid="asean-business-vertical-latest-list"
          className={"flex flex-col divide-y"}
        >
          {latestStories.map((article) => {
            const uniqueName = article.sections?.[0]?.uniqueName;

            if (!uniqueName) return null;

            return (
              <li className="py-4" key={article.id}>
                <BasicCard
                  id={article.id}
                  slug={article.slug}
                  media={article.media}
                  title={
                    article.displaySetting?.displayHeadline || article.title
                  }
                  kicker={article.kicker?.fields?.[0]?.value}
                  blurb={getArticleBlurb(article)}
                  paywall={article.paywall?.contentAccess === "1"}
                  variations={variant}
                  created={article.created}
                  edited={article.edited}
                  updated={article.updated}
                  hasUpdatedTimestampDisplay={
                    article.displaySetting?.hasUpdatedTimeDisplayed
                  }
                  defaultImage={{ directoryName: uniqueName }}
                />
              </li>
            );
          })}
        </ul>
        <LoadMore
          rootClassName="my-4"
          onLoadMore={handleLoadMore}
          hasMore={hasMoreStories}
        />
      </div>

      <NewsletterSignUpPanel
        rootClassName="mb-3"
        descriptionComponent={<AseanNewsletterDescription />}
        newsletter={BT_NEWSLETTERS.filter(
          (newsletter) =>
            newsletter.type === NEWSLETTER_TYPE.MORNING ||
            newsletter.type === NEWSLETTER_TYPE.EVENING
        )}
        btnColors="bg-verticals-ge text-white hover:bg-white hover:text-verticals-ge"
        linkColors="text-verticals-ge"
      />

      <FooterVerticalBanner
        verticalImage={{
          width: 390,
          height: 79,
          url: aseanLogo,
          alt: "ASEAN Business",
        }}
        verticalDescription={ASEAN_DESCRIPTION}
      />
    </div>
  );
}
