import { Media } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import { DefaultImage } from "@components/ArticleCard/types";
import { getDefaultImage } from "@util/helpers";

/**
 * Helper function to get thumbnail image.
 * @param {string} title The article title.
 * @param {Media | Media[]} media The article image.
 * @param {DefaultImage} defaultImage The default image.
 * @returns
 */
export const getCardThumbnail = (
  title: string,
  media?: Media | Media[],
  defaultImage?: DefaultImage
) => {
  const _media = media && "length" in media ? media?.[0] : media;

  const mediaContent =
    _media &&
    (_media.content?.type === "picture" ||
      _media.content?.type === Type.ArticleBrightcoveVideo ||
      _media.content?.type === Type.ArticleYoutubeVideo)
      ? _media.content
      : undefined;
  const thumbnail =
    _media && mediaContent
      ? mediaContent
        ? { ...mediaContent, alt: title }
        : { ..._media, alt: title }
      : undefined;

  const defaultThumbnail = defaultImage
    ? {
        url: getDefaultImage(defaultImage?.directoryName),
        alt: title,
      }
    : undefined;

  return thumbnail || defaultThumbnail;
};
