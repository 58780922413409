import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { ArticleDataObject } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { MyBTKeywordsDataList } from "@app/types/Page";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import { topMobileNav } from "@components/Header/constant";
import Header from "@components/Header/Header";
import HomepageMasthead from "@components/Header/HomepageMasthead";
import MetaTags from "@components/MetaTags/MetaTags";
import MyBTHomeCarousel from "@components/MyBT/MyBTHomeCarousel";
import HomepageCarouselNewsletter from "@components/Newsletter/HomepageCarouselNewsletter";
import HomeSkybox from "@components/Skybox/HomeSkybox";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { cn, gaEventTracker, GoogleAdsSlotFactory } from "@util/helpers";
import cx from "classnames";

import Asean from "./Asean/Asean";
import BreakingNews from "./BreakingNews/BreakingNews";
import CompaniesMarkets from "./CompaniesMarkets/CompaniesMarkets";
import Lifestyle from "./Lifestyle/Lifestyle";
import OpinionFeatures from "./OpinionFeatures/OpinionFeatures";
import Overview from "./Overview/Overview";
import Podcasts from "./Podcasts/Podcasts";
import Video from "./Video/Video";
import { myBTRecommendedKeywords } from "../MyBT/constants";

type HomeContentProps = {
  title: string;
  overview: ArticleDataObject[];
  breakingNews: ArticleDataObject[];
  companiesMarkets: ArticleDataObject[];
  opinionFeatures: ArticleDataObject[];
  verticals: ArticleDataObject[];
  lifestyle: ArticleDataObject[];
  lifestyleCarousel: ArticleDataObject[];
  podcasts: ArticleDataObject[];
  myBTData: MyBTKeywordsDataList;
};

export default function HomeContent({
  title,
  overview,
  breakingNews,
  companiesMarkets,
  opinionFeatures,
  verticals,
  lifestyle,
  lifestyleCarousel,
  podcasts,
  myBTData,
}: HomeContentProps): React.ReactElement {
  const [myBTVisible, setMyBTVisible] = useState(true);

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "home" },
    });

  const seeMore =
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.REGISTERED ||
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER
      ? "/mybt/onboard"
      : "/mybt/intro";

  const { ref: myBTRef, inView: myBTBlock } = useInView();

  if (myBTBlock && myBTVisible) {
    gaEventTracker("mybt", "visible", window.location.href);
    setMyBTVisible(false);
  }

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description="THE BUSINESS TIMES - Find latest business & financial news including analysis and opinion on top business stories, stock markets in Singapore, Asia-Pacific & global market news and more at The Business Times."
        ogType="website"
        articlePageclass="frontpage"
        slug="/"
        keywords={[
          "singapore budget 2015",
          "singapore",
          "budget 2015",
          "business & financial news",
        ]}
      />

      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1("homepage"),
          adsClassName:
            "min-h-[90px] flex flex-col justify-center items-center",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial("homepage")}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header isFromHome masthead={<HomepageMasthead />} />

      <GAData title="BT_Homepage" level2="home" />

      <Container rootClassName="pt-7 text-gray-850">
        <Row>
          <Column rootClassName="mb-1 mx-auto lg:hidden ">
            {/* <StockTicker /> */}
            <div className="flex gap-4">
              {topMobileNav.map((nav) => {
                return (
                  <div className="group" key={nav.key}>
                    <Link
                      key={nav.key}
                      role="link"
                      to={`${nav.link}?ref=home-menubar`}
                      className={cn(
                        "font-poppins text-lg font-medium text-gray-515",
                        {
                          "font-semibold text-gray-850": nav.key === "home",
                        }
                      )}
                      reloadDocument
                    >
                      {nav.label}
                    </Link>
                    <div
                      className={cx(
                        "mx-auto mt-1 h-[2px] w-[3rem] bg-gray-850 transition-all duration-300",
                        nav.key === "home" ? "opacity-100" : "opacity-0",
                        "group-hover:opacity-100"
                      )}
                    ></div>
                  </div>
                );
              })}
            </div>
          </Column>

          <Column rootClassName="w-full mb-10">
            <Overview data={overview} testId="homepage-top-stories" />
          </Column>

          <Column rootClassName="w-full mb-10">
            <HomeSkybox />
          </Column>

          <>
            {breakingNews.length > 0 ? (
              <Column rootClassName="w-full mb-10">
                <BreakingNews
                  data={breakingNews}
                  rootClassName="border-t border-black pt-3"
                  testId="homepage-breaking-news"
                />
              </Column>
            ) : null}
          </>

          <Column rootClassName="w-full mb-10">
            <div ref={myBTRef}>
              <MyBTHomeCarousel
                results={myBTData}
                keywords={myBTRecommendedKeywords}
                title="INTRODUCING"
                page="home"
                showTooltip={false}
                rootClassName="border-t pt-3 border-black"
                disablePaywall={
                  OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
                }
                seeAlsoLink={seeMore}
                testId="homepage-mybt"
              />
            </div>
          </Column>

          <Container>
            <Row>
              <div className="mx-3 mb-3 h-[1px] w-full bg-gray-850"></div>
              <Column rootClassName="w-full lg:pr-6 lg:w-8/12 mb-10 lg:border-r border-gray-175 ">
                <CompaniesMarkets
                  data={companiesMarkets}
                  testId="homepage-companies-markets"
                />
              </Column>

              <Column
                rootClassName="w-full lg:w-4/12 mb-10"
                data-testid="homepage-st-stocks"
              >
                <iframe
                  className="w-full lg:pl-3"
                  src="https://sph.shareinvestor.com/bt_stock_chart_frame_2.html"
                  title="BT Stock Chart"
                  loading="lazy"
                  sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                  width="100%"
                  height={537}
                ></iframe>
              </Column>
            </Row>
          </Container>

          <Column rootClassName="w-full mb-10">
            <OpinionFeatures
              data={opinionFeatures}
              rootClassName={"border-t border-black pt-3"}
              testId="homepage-opinion-features"
            />
          </Column>
        </Row>
      </Container>

      <div className="mb-10 w-full">
        <Container>
          <Asean data={verticals} testId="homepage-verticals" />
        </Container>
      </div>

      <Container>
        <Row>
          <div className="mx-3 mb-3 h-[1px] w-full bg-gray-850"></div>
          <Column rootClassName="w-full lg:pr-6 lg:w-8/12 mb-10 lg:border-r border-gray-175">
            <Podcasts data={podcasts} testId="homepage-podcasts" />
          </Column>

          <Column rootClassName="w-full lg:w-4/12 mb-10 lg:pl-6">
            <div className="mb-3 block h-[1px] w-full bg-gray-850 lg:hidden"></div>
            <Video testId="homepage-videos" />
          </Column>
        </Row>
      </Container>

      <Container>
        <Row rootClassName="mb-7">
          <div className="mx-3 mb-3 h-[1px] w-full bg-gray-850"></div>
          <Column rootClassName="w-full">
            <HomepageCarouselNewsletter
              rootClassName="mb-6"
              testId="homepage-newsletter-carousel"
            />
          </Column>
        </Row>
      </Container>

      <div className="mb-10 w-full bg-gray-850 py-10">
        <Container>
          <Lifestyle
            data={lifestyle}
            carouselData={lifestyleCarousel}
            testId="homepage-lifestyle-carousel"
          />
        </Container>
      </div>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish("homepage"),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </div>
  );
}
