import { ArticleDataObject } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { BlockTitle } from "@blocks/Blocks";
import Advertisement from "@components/Advertisement/Advertisement";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import {
  getKickerFromArticleDataObject,
  GoogleAdsSlotFactory,
} from "@util/helpers";
import cx from "classnames";

export type WealthRealEstateProps = {
  data: ArticleDataObject[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function WealthRealEstate({
  data = [],
}: WealthRealEstateProps): React.ReactElement {
  const topStory = data?.slice(0, 1);
  const subStories = data?.slice(1, 4);

  const variant: CardVariations = {
    content: {
      width: "w-full",
      extraClass: "bg-gray-150 p-3",
    },
    kicker: {
      color: "text-gray-515",
    },
  };

  return (
    <>
      <Row data-testid="wealth-real-estate-component">
        <Column rootClassName="w-full">
          <BlockTitle
            text="REAL ESTATE"
            link="/section/property"
            rootClassName="mb-3 border-t border-black pt-3"
          />
        </Column>

        <Column rootClassName="pb-6 w-full md:w-6/12 lg:w-4/12">
          {topStory?.map((article) => {
            const { id, title, media, paywall, sections, slug } = article;

            return (
              <BasicCard
                slug={slug}
                rootClassName="h-full items-start lg:flex-col"
                id={id}
                title={title}
                key={id}
                media={media}
                kicker={getKickerFromArticleDataObject(article)}
                paywall={paywall?.contentAccess === "1"}
                variations={variant}
                section={sections?.[0]}
                defaultImage={{
                  directoryName: "property",
                }}
              />
            );
          })}
        </Column>

        <Column rootClassName="pb-6 w-full md:w-6/12 lg:w-4/12 flex flex-col">
          {subStories.map((article, index: number) => {
            const { id, title, paywall, slug } = article;

            return (
              <BasicCard
                key={`${id}_${index}`}
                id={id}
                slug={slug}
                title={title}
                kicker={getKickerFromArticleDataObject(article)}
                paywall={paywall?.contentAccess === "1"}
                variations={variant}
                rootClassName={cx("flex-grow", {
                  "border-b border-gray-175": subStories.length - 1 != index,
                })}
              />
            );
          })}
        </Column>

        <Column rootClassName="pb-6 w-full md:w-6/12 lg:w-4/12 flex">
          <Advertisement
            rootClassName="bg-stripes py-5"
            adUnitProps={{
              type: AdvertisementTypeEnum.IMU2,
              slot: GoogleAdsSlotFactory.imu2("wealth"),
            }}
          />
        </Column>
      </Row>
    </>
  );
}
