/* eslint-disable no-console */
import { DataFetcher, WebFetcher } from "@app/data";
import { RouteFactory, routesPodcastsKeywords } from "@app/routePaths";
import type { ArticleDataObject, Section, Tag } from "@app/types/Cue";
import type { PodcastKeywordContext, TermContext } from "@app/types/Page";
import { genKeywordPageQuery } from "@caas/queries/genKeywordPageQuery";
import { genSectionKeywordQuery } from "@caas/queries/genSectionKeywordQuery";
import { genSectionQuery } from "@caas/queries/genSectionQuery";
import { queryCaasOpenSearch } from "@caas/queryCaasOpenSearch";
import { TrimmedBreakingNewsArticle } from "@caas/types";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import { MyBtConfigResponse, MyBtUpdateResponse } from "@pages/MyBT/types";
import { ResponseType, TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { DRUPAL_API_ENDPOINT, sectionNavigationItems } from "@util/constant";
import { textToSlug } from "@util/helpers";
import { santizeArticleDataObjects } from "@util/sanitization/sanitizeArticleDataObjects";
import axios, { HttpStatusCode } from "axios";
import { startCase } from "lodash-es";

import { getSectionObject, getTagObject } from "./helpers";

export const sectionRequests = {
  fetchTrimmedBreakingNews: async (): Promise<TrimmedBreakingNewsArticle[]> => {
    try {
      const response = await axios.get("/_plat/api/v1/trimmed-breaking-news");
      return response.data;
    } catch (error) {
      return [];
    }
  },

  fetchArticlesByKeywords: async (tags: Tag[], size = 10) => {
    const uriList = tags.map((tag) => tag.uri);
    const response = await axios.post("/_plat/api/v1/mybt", { uriList, size });
    return response;
  },

  fetchMyBtFollowedKeywords: async (
    userType: OKTAUserTypeEnum,
    aovisitorid: string
  ) => {
    const response = await axios.get<MyBtConfigResponse>(
      `${DRUPAL_API_ENDPOINT}/api/mybt/v2/follow-tags/getConfig`,
      { headers: { usertype: userType, aovisitorid: aovisitorid } }
    );

    return <Tag[] | null>response.data.tags.map(({ name }) => {
      return { name: name, uri: textToSlug(name) };
    });
  },

  updateMyBtFollowedKeywords: async (
    userType: OKTAUserTypeEnum = OKTAUserTypeEnum.ANONYMOUS,
    aovisitorid: string,
    tags: Tag[]
  ) => {
    if (userType == OKTAUserTypeEnum.ANONYMOUS)
      return <MyBtUpdateResponse>{
        code: 403,
        message: "Permission denied. User is anonymous.",
      };

    const response = await axios.post<MyBtUpdateResponse>(
      `${DRUPAL_API_ENDPOINT}/api/mybt/v2/follow-tags/update`,
      { tags: tags.map((tagToAdd) => tagToAdd.name) },
      { headers: { usertype: userType, aovisitorid: aovisitorid } }
    );

    return response.data;
  },

  deleteMyBtFollowedKeywords: async (
    userType: OKTAUserTypeEnum = OKTAUserTypeEnum.ANONYMOUS,
    aovisitorid: string,
    tags: Tag[]
  ) => {
    if (userType == OKTAUserTypeEnum.ANONYMOUS)
      return <MyBtUpdateResponse>{
        code: 403,
        message: "Permission denied. User is anonymous.",
      };

    const response = await axios.post<MyBtUpdateResponse>(
      `${DRUPAL_API_ENDPOINT}/api/mybt/v2/follow-tags/delete`,
      { tags: tags.map((tagToAdd) => tagToAdd.name) },
      { headers: { usertype: userType, aovisitorid: aovisitorid } }
    );

    return response.data;
  },
};

export const fetchSectionDataV2: DataFetcher<TermContext> = async function (
  this: { sectionUniqueName: string },
  params: Record<string, unknown> & Array<unknown>
) {
  const [parentCategory, childCategory] = (
    this.sectionUniqueName ?? (params[0] as string).replace("/", "")
  ).split("/");

  let matchedCategory = parentCategory;

  // TODO: Fix the issue of retrieving data for bt-luxe
  if (
    childCategory &&
    parentCategory !== "lifestyle" &&
    childCategory !== "bt-luxe"
  ) {
    matchedCategory += `_${childCategory}`;
  }

  // Set section to paid-press-release on /pr page.
  if (matchedCategory === RouteFactory.pr.replace("/", "")) {
    matchedCategory = "paid-press-release";
  }

  const query = genSectionQuery(matchedCategory, 10);
  const response = await queryCaasOpenSearch(query);

  const overview = response.payload?.hits.hits?.map((article) => {
    const articleContext = article._source.data.context;
    return {
      ...articleContext,
      sort: article.sort,
      slug: articleContext.urlPath,
    };
  });

  const sanitizedOverview = santizeArticleDataObjects(overview);
  const sectionObectFromArticle = getSectionObject(
    overview?.[0]?.sections,
    matchedCategory
  );

  // Feel like this should return an error and dont process anymore if sectionobject is not found.

  const sectionObjectFromPath: Section = {
    parent: {
      uniqueName: "ece_frontpage",
      name: "Home",
      directoryName: "frontpage",
    },
    uniqueName: matchedCategory,
    name: startCase(matchedCategory.replaceAll("-", " ").toLowerCase()),
    href: `/${matchedCategory}/`,
    parameters: [],
    directoryName: matchedCategory,
  };

  return {
    type: ResponseType.SUCCESS,
    statusCode: 200,
    kind: "section",
    data: {
      entity: sectionObectFromArticle || sectionObjectFromPath,
      title: renderPageTitleV2({
        kind: "section",
        termName:
          sectionNavigationItems[matchedCategory.replace("_", "/")].label,
      }),
      overview: sanitizedOverview,
    },
  };
};

export const fetchMoreStoriesData = async function (
  category: string,
  parentCategory: string,
  article: ArticleDataObject
): Promise<ArticleDataObject[]> {
  try {
    const response = await axios.post("/_plat/api/v1/more-stories", {
      category, // "companies-markets_banking-finance" || "food-drink"
      parentCategory, // "companies-markets" || "keywords"
      sort: article.sort, // [1703168467000, "2166527"]
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchMoreBrandedContentData = async function (
  article: ArticleDataObject
): Promise<ArticleDataObject[]> {
  try {
    const response = await axios.post("/_plat/api/v1/more-stories-by-kicker", {
      kicker: "Branded Content",
      sort: article.sort,
      size: 10,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getPageSize = (path: string): number => {
  if (routesPodcastsKeywords.includes(path)) return 5;
  return 10;
};

export const fetchPodcastKeywordData: WebFetcher<
  TRouteWithRedirect<PodcastKeywordContext, string>
> = async ({ urlComponent }) => {
  const keywordUri =
    urlComponent && urlComponent.path
      ? urlComponent.path.replace("/", "").split("/")[1]
      : "";

  const query = genSectionKeywordQuery(
    RouteFactory.podcasts.replace("/", ""), // "podcasts"
    `/keywords/${keywordUri}`, // ie /keywords/money-hacks
    5 // pageSize,
  );
  const response = await queryCaasOpenSearch(query);

  const overview = response.payload?.hits.hits?.map((article) => {
    const articleContext = article._source.data.context;
    return { ...articleContext, sort: article.sort };
  });

  const sanitizedOverview = santizeArticleDataObjects(overview);
  const tag = getTagObject(overview[0]?.tags, keywordUri);

  return {
    type: ResponseType.SUCCESS,
    statusCode: HttpStatusCode.Ok,
    payload: {
      kind: "keyword",
      data: {
        entity: tag,
        title: renderPageTitleV2({
          kind: "keyword",
          termName: tag.name,
        }),
        overview: sanitizedOverview,
      },
    },
  };
};

export const fetchKeywordDataV2: DataFetcher<TermContext> =
  async function (props: {
    urlComponent: {
      path: string;
      index: number;
      params: object;
    };
    variant: string;
  }) {
    const path = props.urlComponent.path;
    const keyword = path.replace("/", "").split("/")[1];
    const pageSize = getPageSize(path);
    const query = genKeywordPageQuery(`/keywords/${keyword}`, pageSize);
    const response = await queryCaasOpenSearch(query);

    const overview = response.payload?.hits.hits?.map((article) => {
      const articleContext = article._source.data.context;
      return {
        ...articleContext,
        sort: article.sort,
        slug: articleContext.urlPath,
      };
    });

    const sanitizedOverview = santizeArticleDataObjects(overview);
    const tag = getTagObject(overview[0].tags, keyword);

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      kind: "keyword",
      data: {
        entity: tag,
        title: renderPageTitleV2({
          kind: "keyword",
          termName: tag.name,
        }),
        overview: sanitizedOverview,
      },
    };
  };
