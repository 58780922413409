import { Fragment, useState } from "react";
import type { UserInfoType } from "@app/hooks/useUserInfo";
import { useWindowSize } from "@app/hooks/useWindowSize";
import { userInfoEnums } from "@app/types/enums";
import {
  mySPHOpenLogout,
  mySPHOpenResendVerificationMail,
  mySPHOpenUpdatePassword,
} from "@app/util/helpers";
import { getIconSrc } from "@components/Button/helpers";
import { IconName } from "@components/Button/types";
import Icon from "@components/Icon/Icon";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import cx from "classnames";

export type UserInfoProps = {
  rootClassName?: string;
  userInfo: UserInfoType;
};

/**
 *
 * @param rootClassName top-level css class override
 * @param userInfo user data decoded from JWT
 * @see https://docs.google.com/document/d/1obM9zIuU0Cj6UlI3mkHXGWDEIAGpCx029iiuV_kEwUU/edit# for API usage of window._mySPHObj
 */
export default function UserInfo({
  rootClassName,
  userInfo,
}: UserInfoProps): React.ReactElement {
  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false);

  const setPanelClose = () => {
    setIsPanelVisible(false);
  };
  const { isScreenLG } = useWindowSize();
  return (
    <div className={cx("relative", rootClassName)}>
      <button
        className={cx("flex flex-row items-center justify-center")}
        onClick={() => {
          setIsPanelVisible(!isPanelVisible);
        }}
        aria-label="Account"
      >
        {!isScreenLG ? (
          <img
            className="block lg:hidden"
            src={getIconSrc(IconName.profile)}
            width="48"
            height="48"
            alt="hamburger icon"
          />
        ) : (
          <span className="justify-centerrounded-[25px] hidden h-9 items-center px-3 font-poppins text-2xs font-semibold leading-none tracking-tightest text-gray-850 duration-300 hover:bg-gray-250 lg:flex">
            Account
          </span>
        )}
      </button>

      {isPanelVisible ? (
        <ul
          className={cx(
            "absolute right-0 top-7 z-10 hidden w-[300px] overflow-hidden rounded-[0.25rem] border border-gray-175 bg-white pb-3 shadow-userInfoPanel lg:block"
          )}
        >
          {userInfo?.sub === userInfoEnums.unverified ? (
            <li
              onClick={mySPHOpenResendVerificationMail}
              className="rounded-t-[0.25rem] bg-gray-850 py-4 text-center font-poppins font-normal text-white"
            >
              Please verify your email
            </li>
          ) : null}
          <li className="mx-5 cursor-default border-b border-gray-175 py-1 pt-3 font-semibold uppercase text-gray-350 ">
            Welcome
            <span className="word-break ml-1">{userInfo?.lastname}</span>
          </li>
          <li className="cursor-pointer px-5 py-2 hover:bg-gray-175">
            <a
              target="_blank"
              href={`https://subscribe.sph.com.sg/your-account/?utm_campaign=manageaccount&utm_medium=sph-publication&utm_source=bt&utm_content=manageaccountlink-dropdown&pub-code=bt${encodeURIComponent(
                location.href
              )}`}
              rel="noreferrer"
            >
              Manage Account
            </a>
          </li>
          {userInfo ? (
            <span id="sph_user_name" className="hidden">
              {encodeURI(userInfo.loginId)}
            </span>
          ) : null}
          <li
            className="cursor-pointer px-5 py-2 hover:bg-gray-175"
            onClick={mySPHOpenUpdatePassword}
          >
            Update your password
          </li>
          <li
            className="cursor-pointer px-5 py-2 hover:bg-gray-175"
            onClick={mySPHOpenLogout}
          >
            Logout
          </li>
        </ul>
      ) : null}

      <div className="block lg:hidden">
        <Modal
          isOpen={isPanelVisible && !isScreenLG}
          setIsClose={setPanelClose}
          userInfo={userInfo}
        />
      </div>
    </div>
  );
}
export type ModalProps = {
  isOpen: boolean;
  setIsClose: () => void;
  userInfo: UserInfoType;
};

export const Modal = ({ isOpen, setIsClose, userInfo }: ModalProps) => {
  const handleOnClose = () => {
    setIsClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={handleOnClose}>
        <div className="fixed inset-0 overflow-y-auto">
          <TransitionChild
            as={Fragment}
            enter="transition ease duration-500 transform"
            enterFrom="opacity-0 translate-x-8/10"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <DialogPanel className="h-full w-full transform bg-white transition-all lg:max-w-[260px]">
              <div
                className={cx(
                  "flex cursor-pointer justify-end border-b border-gray-175 p-2 opacity-100 hover:opacity-75 lg:hidden"
                )}
                onClick={handleOnClose}
              >
                <Icon type="close" fill="#2b2b2b" size={30} />
              </div>
              <ul>
                {userInfo?.sub === userInfoEnums.unverified ? (
                  <li
                    onClick={mySPHOpenResendVerificationMail}
                    className="rounded-t-[0.25rem] bg-gray-850 py-4 text-center font-poppins font-normal text-white"
                  >
                    Please verify your email
                  </li>
                ) : null}
                <li className="mx-8 cursor-default border-b border-gray-175 py-1 pt-3 font-semibold uppercase text-gray-350 ">
                  Welcome
                  <span className="word-break ml-1">{userInfo?.lastname}</span>
                </li>
                <li className="cursor-pointer px-8 py-2 font-poppins font-semibold hover:bg-gray-175">
                  <a
                    target="_blank"
                    href={`https://subscribe.sph.com.sg/your-account/?utm_campaign=manageaccount&utm_medium=sph-publication&utm_source=bt&utm_content=manageaccountlink-dropdown&pub-code=bt${encodeURIComponent(
                      location.href
                    )}`}
                    rel="noreferrer"
                  >
                    Manage Account
                  </a>
                </li>
                {userInfo ? (
                  <span id="sph_user_name" className="hidden">
                    {encodeURI(userInfo.loginId)}
                  </span>
                ) : null}
                <li
                  className="cursor-pointer px-8 py-2 font-poppins font-semibold hover:bg-gray-175"
                  onClick={mySPHOpenUpdatePassword}
                >
                  Update your password
                </li>
                <li
                  className="cursor-pointer px-8 py-2 font-poppins font-semibold hover:bg-gray-175"
                  onClick={mySPHOpenLogout}
                >
                  Logout
                </li>
              </ul>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};
