import { cn } from "@util/helpers";

import ThriveSubscriptionForm from "./ThriveSubscriptionForm";

type ThriveCenterSubscriptionProps = {
  className?: string;
};

export default function ThriveCenterSubscription({
  className,
}: ThriveCenterSubscriptionProps): React.ReactElement {
  return (
    <div
      className={cn("relative overflow-hidden py-10 text-center", className)}
      data-testid="thrive-center-subscription-component"
    >
      <div className="relative -ml-2 w-[101vw] -rotate-2 border-y-2 border-black bg-white pb-60 pt-56"></div>

      <div className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 p-10 text-center lg:w-auto lg:p-20">
        <p className="m-0 p-0 text-7xl lg:text-9xl">Maximise your time.</p>

        <p className="mt-0 p-0">
          The good stuff on work and money, every Friday.
        </p>

        <ThriveSubscriptionForm
          className={cn(
            "mx-auto mt-4 w-full border border-black bg-white shadow-thriveButton transition-all duration-150 hover:shadow-thriveNewsletterHover lg:w-fit"
          )}
        />
      </div>
    </div>
  );
}
