import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import sampleArticle from "@components/ArticleCard/__tests__/fixtures/magazine.json";
import WealthMagazineArticle from "@components/ArticleCard/WealthMagazineArticle";
import WealthMagazineHero from "@components/ArticleCard/WealthMagazineHero";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Header from "@components/Header/Header";
import LoadMore from "@components/LoadMore/LoadMore";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import type { SectionDefaultProps } from "@pages/Section/types";
import { sectionNavigationItems } from "@util/constant";
import { GoogleAdsSlotFactory } from "@util/helpers";

export default function WealthMagazineLayout({
  context: {
    data: { title },
  },
  parentCategory,
  childCategory,
}: SectionDefaultProps): ReactElement {
  // TODO: Replace mock data
  const mockArticle = new Array(1).fill(sampleArticle)[0];
  const magazineArticles = new Array(10).fill(sampleArticle);

  // TODO: Pull data
  const handleLoadMore = async () => {
    //
  };
  const hasMoreStories = true;

  const mostPopular = useMostReadArticleList();
  const breakingNews = useTrimmedBreakingNews();

  return (
    <div className="w-full">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(parentCategory || ""),
          adsClassName:
            "min-h-[90px] flex flex-col justify-center items-center",
        }}
      />

      <Header />

      {parentCategory && sectionNavigationItems?.[parentCategory] ? (
        <div className="flex-none bg-gray-250">
          <Breadcrumb
            className="container m-auto"
            parentCategory={parentCategory}
            childCategory={childCategory}
            categoryDataMapping={sectionNavigationItems}
          />
        </div>
      ) : null}

      <div className="container -mx-3 flex flex-row flex-wrap">
        <div className="my-4 w-full px-3 lg:w-8/12">
          <div className="my-8">
            <div className="my-4 w-full bg-gray-150 p-4 text-center font-public-sans text-3xl font-bold lg:hidden">
              MAGAZINES
            </div>
            <WealthMagazineHero articleData={mockArticle} />
          </div>
          <div className="mb-8 grid grid-cols-1 gap-8 overflow-auto lg:grid-cols-2">
            {magazineArticles.map((article) => {
              return (
                <div key={article.id}>
                  <WealthMagazineArticle articleData={mockArticle} />
                </div>
              );
            })}
          </div>
          <LoadMore onLoadMore={handleLoadMore} hasMore={hasMoreStories} />
        </div>
        <div className="mt-12 w-full px-3 lg:w-4/12">
          <Advertisement
            rootClassName="bg-stripes mb-5 py-5"
            adUnitProps={{
              type: AdvertisementTypeEnum.IMU2,
              slot: GoogleAdsSlotFactory.imu2(parentCategory as string),
            }}
          />

          <BreakingNews
            data={breakingNews}
            rootClassName="max-w-[300px] mx-auto mb-6"
          />

          <MostPopular
            data={mostPopular}
            rootClassName="max-w-[300px] mx-auto"
          />
        </div>
      </div>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(parentCategory || ""),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </div>
  );
}
