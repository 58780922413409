import type { Params } from "react-router-dom";
import { Section, Tag } from "@app/types/Cue";
import DefaultSectionLayout from "@pages/Section/layouts/Default";
import ESGSectionLayout from "@pages/Section/layouts/ESG";
import AseanBusinessVertical from "@pages/Section/layouts/Verticals/AseanBusiness/AseanBusinessVertical";
import AseanBusinessVerticalLatest from "@pages/Section/layouts/Verticals/AseanBusiness/AseanBusinessVerticalLatest";
import GlobalEnterpriseVertical from "@pages/Section/layouts/Verticals/GlobalEnterprise/GlobalEnterpriseVertical";
import GlobalEnterpriseVerticalLatest from "@pages/Section/layouts/Verticals/GlobalEnterprise/GlobalEnterpriseVerticalLatest";
import SMEVertical from "@pages/Section/layouts/Verticals/SME/SMEVertical";
import SMEVerticalLatest from "@pages/Section/layouts/Verticals/SME/SMEVerticalLatest";
import WealthMagazineLayout from "@pages/Section/layouts/Wealth/components/WealthMagazine";
import IndividualMagazineLayout from "@pages/Section/layouts/Wealth/IndividualMagazine";
import WealthLayout from "@pages/Section/layouts/Wealth/Wealth";
import { TagType } from "@pages/Section/types";
import { VerticalName } from "@util/constant";
import { slugToText } from "@util/helpers";

export const LayoutFactory = ({
  parentCategory = "",
  childCategory = "",
  variant,
}: Params) => {
  const sectionUniqueName = `${parentCategory}/${childCategory}`;

  switch (true) {
    case sectionUniqueName === "esg/":
      return ESGSectionLayout;

    case sectionUniqueName === "international/global" && variant === "latest":
      return GlobalEnterpriseVerticalLatest;

    case sectionUniqueName === "international/asean" && variant === "latest":
      return AseanBusinessVerticalLatest;

    case sectionUniqueName === "singapore/smes" && variant === "latest":
      return SMEVerticalLatest;

    case sectionUniqueName === "wealth/":
      return WealthLayout;

    case sectionUniqueName === "wealth/supplements":
      return WealthMagazineLayout;

    case sectionUniqueName.startsWith("wealth-"):
      return IndividualMagazineLayout;

    case parentCategory === "keywords":
      return DefaultSectionLayout;

    default:
      return DefaultSectionLayout;
  }
};

export const VerticalLayoutFactory = (verticalName: VerticalName) => {
  switch (verticalName) {
    case "asean":
      return AseanBusinessVertical;
    case "sme":
      return SMEVertical;
    case "ge":
      return GlobalEnterpriseVertical;
    default:
      return DefaultSectionLayout;
  }
};

/**
 * Helper function to format a text that is suited for Ads Team's requirements.
 * Examples of keyword text shown on helpers.test.tsx
 * @param {string} text The text that needs to be formatted.
 * @returns {string}
 */
export const getFormattedTextForAds = (text: string): string => {
  return text
    .replaceAll(/&amp;|&/g, "and")
    .replaceAll(/[;/\\]/g, "")
    .toLowerCase();
};

/**
 * Helper function to get the path of Section or Tag
 * @param entity Section or Tag
 * @returns {string|undefined}
 */
export const getTermPath = (entity?: Section | Tag): string | undefined => {
  if (!entity) return;

  if (isEntityTagType(entity)) {
    if (!entity.uri) return;
    return entity.uri.replaceAll("main/", "keywords/");
  }

  if (isEntitySectionType(entity)) {
    return entity.uniqueName.replaceAll("_", "/");
  }
};

/**
 * Helper function to get the tag object from list of tags.
 * @param tags List of tags.
 * @param keywordUri The uri of keyword you wanna search.
 * @returns
 */
export const getTagObject = (tags: Tag[] = [], keywordUri: string): Tag => {
  const trimmedKeywordUri = keywordUri.replace("main/", "");
  const tagKeywordUri = "main/" + trimmedKeywordUri;

  const manuallyGeneratedTag: Tag = {
    name: slugToText(trimmedKeywordUri),
    uri: keywordUri,
    aliases: [],
    fields: [],
    id: "",
    parent: null,
    type: TagType.Keyword,
    urlPath: `/keywords/${trimmedKeywordUri}`,
    urlPathHistory: [],
  };

  return tags.find((tag) => tag.uri === tagKeywordUri) || manuallyGeneratedTag;
};

/**
 * Helper finction to get the section object from list of sections.
 * @param sections List of sections.
 * @param sectionUniqueName The unique name of section you wanna search.
 * @returns
 */
export const getSectionObject = (
  sections: Section[] = [],
  sectionUniqueName: string
) => {
  return sections.find(({ uniqueName }) => uniqueName === sectionUniqueName);
};

/**
 * Helper function to check if entity is Section
 * @param entity The entity to check.
 * @returns {boolean}
 */
export const isEntitySectionType = (
  entity: Section | Tag
): entity is Section => {
  return (
    typeof entity === "object" && entity !== null && "uniqueName" in entity
  );
};

/**
 * Helper function to check if entity is Tag
 * @param entity The entity to check.
 * @returns {boolean}
 */
export const isEntityTagType = (entity: Section | Tag): entity is Tag => {
  return typeof entity === "object" && entity !== null && "uri" in entity;
};
